export function useAccountApi() {
  const API_VER = useRuntimeConfig().API_VER_1
  const API_PROMOTION = useRuntimeConfig().API_PROMOTION
  return {
    PLAN: `${API_VER}/account/plan`,
    UPDATE_PASSWORD: `${API_VER}/updatePassword`,
    UPDATE_INFO: `${API_VER}/updateInfo`,
    VERIFY_EMAIL_API: `${API_VER}/user/send-verify-email`,
    VERIFY_EMAIL_TOKEN: `${API_VER}/user/verify-email-token`,
    HISTORY_TRANSACTION: `${API_VER}/lsgd`,
    HISTORY_TRANSACTION_SUCCESS: `${API_VER}/lsgd/last-success`,
    HISTORY_BETTING: `${API_VER}/lsbAll`,
    HISTORY_TRANSACTION_DETAIL: `${API_VER}/payment/invoice`,
    WITHDRAW_BANK: `${API_VER}/payment/withdrawbank`,
    WITHDRAW_CARD: `${API_VER}/payment/withdrawcard`,
    WITHDRAW_CRYPTO: `${API_VER}/payment/crypto/withdraw`,
    DEPOSIT_INFO: `${API_VER}/payment/indexdeposit`,
    DEPOSIT_NICEPAY: `${API_VER}/payment/nicepay`,
    DEPOSIT_GOPAY: `${API_VER}/payment/deposit-provider`,
    DEPOSIT_CODE_MOMO: `${API_VER}/user/momo/code`,
    DEPOSIT_CARD: `${API_VER}/payment/depositcard`,
    CHECK_INVOICE: (id: string) => `${API_VER}/payment/invoice/${id}`,
    CARD_LIST: `${API_VER}/payment/gwinfo`,
    BANK: `${API_VER}/user/banks`,
    CANCEL_PROMOTION: `${API_VER}/payment/cancelpromotion`,
    CANCEL_NICEPAY: `${API_VER}/payment/cancel-invoice`,
    CRYPTO_NETWORK: `${API_VER}/payment/crypto/get-tranfer-address?network=`,
    UPLOAD_IMAGE: `${API_VER}/transaction`,
    UPDATE_IMAGE: (id: string) => `${API_VER}/payment/binary/statement/${id}`,
    PROFIT_SUMMARY: `${API_VER}/account/summary`,
    COMMISSION: `${API_VER}/account/commission`
  }
}
